<template>
  <div class="hello">
    <h1>提供丰富的图像处理api，基于【火山引擎-人像人体-人像编辑-智能绘图】的所有api（见：https://www.volcengine.com/docs/6791/1279296）</h1>
    <h1>本公司由于资源包 + 抵扣券 + 深度火山合作，故可以提供更低价的API，降低您的成本。并承诺完全基于原火山Api调用，保持所有入参/返参不变，您可无缝迁移</h1>
    <h1 style="background-color: aquamarine;">官网价格0.2元每次调用，我司可给到低至：0.05元每次调用，咨询联系wx:  z544164616</h1>
    <el-divider></el-divider>
    <div style="box-shadow: var(--el-box-shadow-dark)">
      <el-image src="apidesc.jpg"></el-image>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
